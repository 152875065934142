import React from 'react'
import { MDBRow, MDBCol, MDBIcon, MDBContainer } from 'mdbreact'
import './MostUsedTechnologies.css'
// i18n
import '../../i18n'
import { withNamespaces } from 'react-i18next'

const MostUsedTechnologies = ({ t }) => {
  return (
    <div className="MostUsedTechnologies-container">
      <MDBContainer tag="section" className="text-center">
        <p className="lead grey-text w-responsive mb-5 text-left">
          {t(`MostUsedTechnologies.introduction`)}
        </p>
        <br />
        <MDBRow className="mb-5">
          <MDBCol md="4">
            <MDBIcon fab icon="react" size="3x" className="cyan-text" />
            <h5 className="font-weight-bold my-4">React</h5>
            {/* <p className="grey-text mb-md-0 mb-5">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
          </MDBCol>
          <MDBCol md="4">
            <MDBIcon fab icon="vuejs" size="3x" className="green-text" />
            <h5 className="font-weight-bold my-4">Vue</h5>
            {/* <p className="grey-text mb-md-0 mb-5">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
          </MDBCol>
          <MDBCol md="4">
            <MDBIcon fab icon="js" size="3x" className="orange-text" />
            <h5 className="font-weight-bold my-4">JavaScript</h5>
            {/* <p className="grey-text mb-md-0 mb-5">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
          </MDBCol>
        </MDBRow>

        <MDBRow className="mb-5">
          <MDBCol md="4">
            <MDBIcon fab icon="html5" size="3x" className="red-text" />
            <h5 className="font-weight-bold my-4">HTML</h5>
            {/* <p className="grey-text mb-md-0 mb-5">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
          </MDBCol>
          <MDBCol md="4">
            <MDBIcon fab icon="css3-alt" size="3x" className="blue-text" />
            <h5 className="font-weight-bold my-4">CSS</h5>
            {/* <p className="grey-text mb-md-0 mb-5">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
          </MDBCol>
          <MDBCol md="4">
            <MDBIcon fab icon="sass" size="3x" className="pink-text" />
            <h5 className="font-weight-bold my-4">SASS</h5>
            {/* <p className="grey-text mb-md-0 mb-5">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
          </MDBCol>
        </MDBRow>

        <MDBRow className="mb-5">
          <MDBCol md="4">
            <MDBIcon fab icon="bootstrap" size="3x" className="purple-text" />
            <h5 className="font-weight-bold my-4">Bootstrap</h5>
            {/* <p className="grey-text mb-md-0 mb-5">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
          </MDBCol>
          <MDBCol md="4">
            <MDBIcon icon="mobile-alt" size="3x" className="black-text" />
            <h5 className="font-weight-bold my-4">Mobile (Flutter/ React Native)</h5>
            {/* <p className="grey-text mb-md-0 mb-5">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
          </MDBCol>
          <MDBCol md="4">
            <MDBIcon fab icon="jira" size="3x" className="blue-text" />
            <h5 className="font-weight-bold my-4">Jira</h5>
            {/* <p className="grey-text mb-md-0 mb-5">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
          </MDBCol>
        </MDBRow>

        <MDBRow className="mb-5">
          <MDBCol md="4">
            <MDBIcon fab icon="node" size="3x" className="green-text" />
            <h5 className="font-weight-bold my-4">Node</h5>
            {/* <p className="grey-text mb-md-0 mb-5">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
          </MDBCol>
          <MDBCol md="4">
            <MDBIcon fab icon="npm" size="3x" className="red-text" />
            <h5 className="font-weight-bold my-4">npm</h5>
            {/* <p className="grey-text mb-md-0 mb-5">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
          </MDBCol>
          <MDBCol md="4">
            <MDBIcon fab icon="yarn" size="3x" className="cyan-text" />
            <h5 className="font-weight-bold my-4">yarn</h5>
            {/* <p className="grey-text mb-md-0 mb-5">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
          </MDBCol>
        </MDBRow>

        <MDBRow className="mb-5">
          <MDBCol md="4">
            <MDBIcon fab icon="git" size="3x" className="red-text" />
            <h5 className="font-weight-bold my-4">Git</h5>
            {/* <p className="grey-text mb-md-0 mb-5">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
          </MDBCol>
          <MDBCol md="4">
            <MDBIcon fab icon="github" size="3x" className="black-text" />
            <h5 className="font-weight-bold my-4">Github</h5>
            {/* <p className="grey-text mb-md-0 mb-5">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
          </MDBCol>
          <MDBCol md="4">
            <MDBIcon fab icon="bitbucket" size="3x" className="blue-text" />
            <h5 className="font-weight-bold my-4">Bitbucket</h5>
            {/* <p className="grey-text mb-md-0 mb-5">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p> */}
          </MDBCol>
        </MDBRow>

      </MDBContainer>
    </div>
  )
}

export default withNamespaces()(MostUsedTechnologies)
