import React, { useState } from 'react';
import { Link } from 'gatsby';
import Card from '../Card/Card';
import { MDBRow, MDBBtn, MDBContainer } from 'mdbreact';
import images from '../../images';
import './SomePersonalProjects.css';
// i18n
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';

const SomePersonalProjects = ({ t }) => {
  const selectedLang = i18n.languages[0];

  return (
    <div id="SomePersonalProjects-container">
      <MDBContainer tag="section">
        <h2 className="h1-responsive text-center font-weight-bold mb-5">
          {t(`SomePersonalProjects.title`)}
        </h2>
        <p className="lead grey-text w-responsive mx-auto mt-5 mb-4">
          {t(`SomePersonalProjects.description`)}
        </p>
        <MDBRow className="m-0" center>
          <Card
            title="To Do List"
            image={images.toDoList}
            description="Vue, Vuex."
            clickText={selectedLang === 'en' ? 'Take a look' : 'Ver'}
          >
            <p className="modal-text lead grey-text w-responsive mx-auto mb-5 text-justify">
              {t(`SomePersonalProjects.modal_description_project1`)}
            </p>
            {/* <MDBBtn onClick={() => {}}>Tutorial</MDBBtn> */}
            <MDBBtn
              onClick={() =>
                window.open(
                  'https://github.com/chrisureza/Vue-ToDoList',
                  '_blank'
                )
              }
            >
              GitHub Repo
            </MDBBtn>
            <MDBBtn
              onClick={() =>
                window.open('https://vue-vuex-todo.web.app', '_blank')
              }
            >
              Live Demo
            </MDBBtn>
          </Card>
          <Card
            title="Web Chat"
            image={images.webChat}
            description="React, Firebase (Storage, Authentication, Hosting)."
            clickText={selectedLang === 'en' ? 'Take a look' : 'Ver'}
          >
            <p className="modal-text lead grey-text w-responsive mx-auto mb-5 text-justify">
              {t(`SomePersonalProjects.modal_description_project2`)}
            </p>
            {/* <MDBBtn onClick={() => {}}>Tutorial</MDBBtn> */}
            <MDBBtn
              onClick={() =>
                window.open(
                  'https://github.com/chrisureza/React-Chat',
                  '_blank'
                )
              }
            >
              GitHub Repo
            </MDBBtn>
            <MDBBtn
              onClick={() =>
                window.open('https://react-chat-8e594.web.app', '_blank')
              }
            >
              Live Demo
            </MDBBtn>
          </Card>
          <Card
            title="Monday Movies"
            image={images.mondayMovies}
            description="Made with Flutter for Android and iOS."
            clickText={selectedLang === 'en' ? 'Take a look' : 'Ver'}
          >
            <p className="modal-text lead grey-text w-responsive mx-auto mb-5 text-justify">
              {t(`SomePersonalProjects.modal_description_project3`)}
            </p>
            {/* <MDBBtn onClick={() => {}}>Tutorial</MDBBtn> */}
            <MDBBtn
              onClick={() =>
                window.open(
                  'https://github.com/chrisureza/Flutter-Movies',
                  '_blank'
                )
              }
            >
              GitHub Repo
            </MDBBtn>
            <a
              href="https://play.google.com/store/apps/details?id=com.chrisureza.mondaymovies&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank"
            >
              <img
                alt="Get it on Google Play"
                src={images.googlePlayBadge}
                style={{ width: '175px' }}
              />
            </a>
          </Card>
        </MDBRow>
        <MDBRow className="more-projects-btn-container">
          <Link to="/projects">
            <MDBBtn>{t('SomePersonalProjects.moreProjects')}</MDBBtn>
          </Link>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default withNamespaces()(SomePersonalProjects);
