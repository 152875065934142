import React, { useState } from 'react'
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from 'mdbreact'
// i18n
import '../../i18n'
import { withNamespaces } from 'react-i18next'
import './card.css'

const CardExample = props => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleIsModalOpen = () => setIsModalOpen(!isModalOpen)

  return (
    <div className="project-card">
      <MDBCol style={{ maxWidth: '22rem' }}>
        <MDBCard>
          <img className="image img-fluid" src={props.image} alt="" />
          <MDBCardBody>
            <MDBCardTitle>{props.title}</MDBCardTitle>
            <MDBCardText>{props.description}</MDBCardText>
            <MDBBtn onClick={toggleIsModalOpen}>{props.t(props.clickText)}</MDBBtn>
          </MDBCardBody>
        </MDBCard>
        <MDBModal isOpen={isModalOpen} toggle={toggleIsModalOpen} centered>
          <MDBModalHeader toggle={toggleIsModalOpen}>
            {props.title}
          </MDBModalHeader>
          <MDBModalBody>{props.children}</MDBModalBody>
        </MDBModal>
      </MDBCol>
    </div>
  )
}

export default withNamespaces()(CardExample)
