import noImage from './noImage.jpg'
import profilePhoto from './profilePhoto.jpg'
import portfolio from './PortfolioHomePage.jpg'
import toDoList from './toDoList.jpg'
import webChat from './webChat.jpg'
import mondayMovies from './MondayMovies.jpg'
import flutterDesigns from './FlutterDesigns.jpg'
import flutterQRReader from './FlutterQRReader.jpg'
import flutterQRReader2 from './FlutterQRReader2.jpg'
import flutterUserPreferences from './FlutterUserPreferences.jpg'
import flutterProductMarket from './FlutterProductMarket.png'
import flutterNewsApp from './FlutterNewsApp.png'
import googlePlayBadge from './google-play-badge.png'
import gatsbyWebResume from './gatsbyWebResume.png'

export default {
  noImage,
  profilePhoto,
  portfolio,
  toDoList,
  webChat,
  mondayMovies,
  flutterDesigns,
  flutterQRReader,
  flutterQRReader2,
  flutterUserPreferences,
  flutterProductMarket,
  flutterNewsApp,
  googlePlayBadge,
  gatsbyWebResume,
}
