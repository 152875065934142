import React from 'react'
import Layout from '../components/Layout/Layout'
// import Intro from '../components/Mask/Mask'
import SEO from '../components/Seo/Seo'
// import Carousel from '../components/Carousel/Carousel'
import PresentationHero from '../components/PresentationHero/PresentationHero'
import AboutSection from '../components/AboutSection/AboutSection'
import SomePersonalProjects from '../components/SomePersonalProjects/SomePersonalProjects'
import MostUsedTechnologies from '../components/MostUsedTechnologies/MostUsedTechnologies'

const App = () => {
  return (
    <>
      <Layout>
        <SEO
          title="Home"
          keywords={[
            `gatsby`,
            `MDBReact`,
            `react`,
            `Material Design For Bootstrap`,
          ]}
        />
        {/* <Carousel />
          <Intro /> */}
        <PresentationHero />
        <main>
          <AboutSection />
          <MostUsedTechnologies />
          <SomePersonalProjects />
        </main>
      </Layout>
    </>
  )
}

export default App
