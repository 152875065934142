import React from 'react'
import images from '../../images'
import './PresentationHero.css'

const PresentationHero = () => {
  return (
    <div className="PresentationHero-container">
      <div className=" d-flex justify-content-center">
        <img
          className="profile-photo img-fluid"
          src={images.profilePhoto}
          alt=""
        />
      </div>
    </div>
  )
}

export default PresentationHero
