import React from 'react'
import { MDBContainer } from 'mdbreact'
// i18n
import '../../i18n'
import { withNamespaces } from 'react-i18next'

const FeaturesPage = ({ t }) => {
  return (
    <MDBContainer tag="section" className="text-center my-5">
      <h2 className="h1-responsive font-weight-bold my-5">
        {t(`AboutSection.title`)}
      </h2>
      <p className="lead grey-text w-responsive mx-auto mb-5">
        {t(`AboutSection.description`)}
      </p>
    </MDBContainer>
  )
}

export default withNamespaces()(FeaturesPage)
